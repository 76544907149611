import React, { useState, useEffect } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContainerLibraryMenu from "../../components/equipment-library-menu"
import { graphql } from "gatsby" // to query for image data
import { GatsbyImage } from "gatsby-plugin-image"

const ContainerLibraryPage = ({ data }) => {
  let [dimension, setDimension] = useState("MM")
  let [weightDimension, setWeightDimension] = useState("KG")
  useEffect(() => {
    const w = localStorage.getItem("weightDimension") || "KG"
    setWeightDimension(w)
    const l = localStorage.getItem("dimension") || "MM"
    setDimension(l)
  }, [])
  const onDimensionChange = dim => {
    localStorage.setItem("dimension", dim)
    setDimension(dim)
  }

  const onWeightDimChange = dim => {
    localStorage.setItem("weightDimension", dim)
    setWeightDimension(dim)
  }
  return (
    <Layout>
      <SEO title="Equipment Library" />
      <ContainerLibraryMenu
        lengthDim={dimension}
        weightDim={weightDimension}
        onDimensionChange={onDimensionChange}
        onWeightDimChange={onWeightDimChange}
      >
        <div className="container mx-auto pt-16 pb-48 px-6">
          <div className="flex space-x-8">
            <div className="w-full">
              <GatsbyImage
                image={data.heroImage.childImageSharp.gatsbyImageData}
                alt="A lorry parking"
                className="w-full h-auto"
              />
            </div>
            <div className="space-y-4 text-lg">
              <h1 className="my-4 text-4xl font-medium leading-snug tracking-wider">
                Equipment library
              </h1>
              <p>
                This is the standard equipment library that's included in all
                Cargo-Planner licenses. There are over 70 equipment types that
                are ready to use. Check out what is available with the left hand
                menu.
              </p>
              <p>
                Can't find what you're looking for? Cargo-Planner includes a
                feature complete builder for custom containers, trailers and
                pallets. Every piece of equipment in the library is in fact
                built with the Container Builder.
              </p>
            </div>
          </div>
        </div>
      </ContainerLibraryMenu>
    </Layout>
  )
}
export const query = graphql`
  {
    heroImage: file(relativePath: { eq: "stock/lorry-park.jpg" }) {
      base
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`
export default ContainerLibraryPage
