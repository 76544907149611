import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronDown,
  faChevronUp,
  faCog,
} from "@fortawesome/free-solid-svg-icons"
const ContainerLibraryMenu = ({
  selected,
  selectedSet,
  children,
  onDimensionChange,
  onWeightDimChange,
}) => {
  const data = useStaticQuery(graphql`
    query SystemHoldsQuery {
      allRestApiApi2HoldsDefault {
        nodes {
          base_type
          id
          name
          priority
          holdPath: gatsbyPath(
            filePath: "/equipment-library/{restApiApi2HoldsDefault.name}"
          )
          data {
            id
          }
        }
      }
      allRestApiApi2SetsDefault {
        nodes {
          id
          base_type
          name
          data {
            id
          }
          setPath: gatsbyPath(
            filePath: "/equipment-library/{restApiApi2SetsDefault.name}"
          )
        }
      }
    }
  `)

  data.allRestApiApi2HoldsDefault.nodes.sort((a, b) => a.priority - b.priority)

  const initialType =
    selected !== undefined
      ? data.allRestApiApi2HoldsDefault.nodes.find(a => a.data.id === selected)
          ?.base_type
      : "SET-" +
        data.allRestApiApi2SetsDefault.nodes.find(
          a => a.data.id === selectedSet
        )?.base_type
  const dimensions = [
    { name: "Meters", value: "M" },
    { name: "Centimeters", value: "CM" },
    { name: "Millimeters", value: "MM" },
    { name: "Inches", value: "IN" },
  ]
  const weightDims = [
    { name: "Kilograms", value: "KG" },
    { name: "Pounds", value: "LB" },
  ]
  const [dimension, setDimension] = useState("MM")
  const [weightDim, setWeightDimension] = useState("MM")

  useEffect(() => {
    setDimension(localStorage.getItem("dimension") || "MM")
    setWeightDimension(localStorage.getItem("weightDimension") || "KG")
  }, [setDimension])

  const changeDimension = () => {
    const val = document.getElementById("dimensionSelector").value
    setDimension(val)
    onDimensionChange(val)
  }
  const changeWeightDimension = () => {
    const val = document.getElementById("weightDimensionSelector").value
    setWeightDimension(val)
    onWeightDimChange(val)
  }

  const [toggled, setToggled] = useState(initialType || "")

  const toggleType = baseType => {
    if (toggled == baseType) {
      setToggled("")
    } else {
      setToggled(baseType)
    }
  }

  const baseTypes = [
    { name: "Sea Containers", base_type: "SEA" },
    { name: "Air Pallets & Containers", base_type: "AIR" },
    { name: "Trucks and Trailers", base_type: "ROAD" },
    { name: "Pallets", base_type: "PALL" },
    // { name: "Breakbulk Sea Vessels", base_type: "SHIP" },
  ]
  const setBaseTypes = [
    { name: "Aircrafts", base_type: "AIR" },
    { name: "Sea Vessels", base_type: "SEA" },
  ]

  return (
    <div className="flex items-stretch">
      <aside className="sidebar w-64">
        <div className="overflow-y-auto py-4 px-1 bg-white dark:bg-gray-800 h-full border-r pb-16">
          <h2 className="text-xl pb-4 pt-2 px-2 font-bold text-gray-700">
            <Link to="/equipment-library/">Equipment Library</Link>
          </h2>
          <div className="w-full p-2 border-b border-t mb-4 text-gray-500">
            <FontAwesomeIcon icon={faCog} size="sm" className="text-gray-400" />
            <span className="text-sm px-2">Units</span>
            <select
              className="w-full my-2"
              id="dimensionSelector"
              onChange={changeDimension}
              value={dimension}
            >
              {dimensions.map(dim => {
                return (
                  <option key={dim.value} value={dim.value}>
                    {dim.name}
                  </option>
                )
              })}
            </select>
            <select
              className="w-full my-2"
              id="weightDimensionSelector"
              onChange={changeWeightDimension}
              value={weightDim}
            >
              {weightDims.map(dim => {
                return (
                  <option key={dim.value} value={dim.value}>
                    {dim.name}
                  </option>
                )
              })}
            </select>
          </div>
          <ul className="space-y-2">
            {baseTypes.map(type => {
              const containers = data.allRestApiApi2HoldsDefault.nodes
                .filter(a => a.base_type === type.base_type)
                .map(r => {
                  return (
                    <li key={r.id} className="my-1">
                      <Link
                        to={r.holdPath}
                        className={
                          "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 " +
                          (selected === r.data.id ? "bg-gray-200" : "")
                        }
                      >
                        <span>{r.name}</span>
                      </Link>
                    </li>
                  )
                })
              return (
                <li className="text-lg overflow-hidden" key={type.base_type}>
                  <button
                    onClick={() => toggleType(type.base_type)}
                    className="hover:bg-gray-100 rounded p-2 flex items-center w-full"
                  >
                    <span className="flex-grow text-left">{type.name}</span>
                    <FontAwesomeIcon
                      icon={
                        toggled != type.base_type ? faChevronDown : faChevronUp
                      }
                      size="xs"
                      className="flex-none"
                    />
                  </button>
                  <ul
                    className={
                      "px-3 transition-all duration-300 " +
                      (toggled !== type.base_type ? "max-h-0" : "max-h-none")
                    }
                  >
                    {containers}
                  </ul>
                </li>
              )
            })}
            {setBaseTypes.map(type => {
              const toggleName = `SET-${type.base_type}`
              const sets = data.allRestApiApi2SetsDefault.nodes
                .filter(a => a.base_type === type.base_type)
                .map(r => {
                  return (
                    <li key={r.id} className="my-1">
                      <Link
                        to={r.setPath}
                        className={
                          "flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 " +
                          (selectedSet === r.data.id ? "bg-gray-200" : "")
                        }
                      >
                        <span>{r.name}</span>
                      </Link>
                    </li>
                  )
                })
              return (
                <li className="text-lg overflow-hidden" key={toggleName}>
                  <button
                    onClick={() => toggleType(toggleName)}
                    className="hover:bg-gray-100 rounded p-2 flex items-center w-full"
                  >
                    <span className="flex-grow text-left">{type.name}</span>

                    <FontAwesomeIcon
                      icon={toggled != toggleName ? faChevronDown : faChevronUp}
                      size="xs"
                      className="flex-none"
                    />
                  </button>
                  <ul
                    className={
                      "px-3 transition-all duration-300 " +
                      (toggled !== toggleName ? "max-h-0" : "max-h-none")
                    }
                  >
                    {sets}
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
      <div className="flex-grow">{children}</div>
    </div>
  )
}

ContainerLibraryMenu.propTypes = {
  selected: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default ContainerLibraryMenu
